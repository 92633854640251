<template>
  <div class="SearchCrawlpage">

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#F9BF3B">
    </loading>

    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Rechercher une page crawlée</h1>
        <div class="form_div">
          <div class="form_content">
            <b-form v-on:submit.prevent="searchCrawlpage()">
              <b-form-group id="crawlpage-url-group" label="Url de la page crawlée" label-for="crawlpage-url-input" class="mandatory-input">
                <b-form-input
                  v-model="url" type="text"
                  maxlength="1000"
                  placeholder="Url"
                  id="crawlpage-url-input"
                  @input="$v.url.$touch()"
                  :state="$v.url.$dirty ? !$v.url.$error : null">
                </b-form-input>
              </b-form-group>
              <b-button
                :disabled="$v.url.$invalid"
                variant="success" 
                size="sm" 
                class="mt-2"
                type="submit"
              >
                Rechercher
              </b-button>
            </b-form>

            <div v-if="crawlpages.length > 0">
              <div v-for="crawlpage in crawlpages" :key="crawlpage.id">
                <b-card class="mt-2">
                  <b-card-title>
                    {{crawlpage.title}}
                  </b-card-title>
                  <b-card-sub-title><em>Page : {{crawlpage.page_name}}</em> <br> {{$dayjs(crawlpage.created_at).format('DD/MM/YYYY - HH:mm')}}</b-card-sub-title>
                  <b-card-text>
                    <div class="row">
                      <div class="col-12">
                        <a :href="crawlpage.url" target="_blank"><small>{{crawlpage.url}}</small></a><br>

                        <strong> Répondant à la requête ? 
                          <span class="text-success" v-if="crawlpage.is_detected">
                            <v-icon name="check"/>
                          </span>
                          <span class="text-danger" v-else >
                            <v-icon name="times-circle"/>
                          </span>
                        </strong>
                        <br>
                        <strong> Lue ? 
                          <span class="text-success" v-if="crawlpage.is_read">
                            <v-icon name="check"/>
                          </span>
                          <span class="text-danger" v-else >
                            <v-icon name="times-circle"/>
                          </span>
                        </strong>
                      </div>
                    </div>
                  </b-card-text>
                </b-card>
              </div>
            </div>
          </div>
        </div>
    </div>


  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { APICrawlPage } from '@/api/APICrawlPage'
import formTitleComponent from '@/components/formTitleComponent'

const apiCrawlPage = new APICrawlPage()

export default {
  name: 'SearchCrawlpage',
  components: {
    formTitleComponent,
    Loading,
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      url: null,
      isLoading: false,

      crawlpages: []
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    }
  },
  validations: {
    url: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(999),
    },
  },
  methods: {
    searchCrawlpage () {
      this.isLoading = true
      apiCrawlPage.searchCrawlpage(this.token, this.url)
      .then((result) => {
        this.crawlpages = result.data
      })
      .catch((error) => {
        throw new Error('TagList searchCrawlpage API Error : ' + String(error))
      })
      .finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>
